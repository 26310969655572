<template>
  <div class="row mt-1">
    <div class="col-12">
      <div>
        <div class="bg-light-primary rounded-top py-half px-1">
          <div class="psh-header mb-0 d-flex align-items-center px-0">
            <div class="psh-title border-0 mr-0">
              <i class="icon icon-psh-logistic-3 text-h1" style="font-size: 2rem;" />
            </div>
            <h4 class="card-title mb-25 text-primary ml-1 flex-grow-1">
              Order Items
            </h4>
          </div>
        </div>
        <div class="border rounded-bottom px-half py-half">
          <div v-for="item in orderItems" :key="item.id" class="d-flex custom-border">
            <div class="align-self-center" role="button" @click="enlargeImage(item.largeImage)">
              <img :src="item.smallImage || require('@/assets/images/undraw/no_images.svg')" style="height: 80px !important; width: 80px !important; margin-right: 0.625rem" class="rounded text-white">
            </div>
            <div class="flex-grow-1 mb-half">
              <small class="text-muted">Product Name</small>
              <h5>
                {{ item.productName }}
              </h5>
                <small class="text-muted">SKU</small>
                <span class="">
                  {{ item.orderSku }}<copy-button :copy-value="item.orderSku" />
                </span>
                <small class="text-muted ml-1">ASIN</small>
                <span>
                  {{ item.productAsin || '-' }}<copy-button :copy-value="item.productAsin" />
                </span>
              <div v-if="currentOrder.platform === 'AmazonSP'" class="d-flex mt-half">
               <fbm-item-weight v-if="hasPermission" :item="item" />
              </div>
            </div>
            <div class="text-center">
              <div class="d-flex">
                <div v-if="currentOrder && currentOrder.fulfillmentStatus !== 'Shipped'" class="text-right m-half">
                  <dx-util-button v-if="hasPermission" icon="search" type="default" class="mr-half" @click="openSearchInventory(item)" />
                </div>
                <div style="margin-right: 5px;">
                  <small class="text-muted">
                    Quantity
                  </small>
                  <h4>{{ item.orderQuantity || '-' }}</h4>
                </div>
                <div>
                  <small class="text-muted">Shipped</small>
                  <h4>{{ item.shippedQuantity || '-' }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <fbm-inventory-search :component-id="fbmInventorySearchCompId" :search-data="searchData" />
    </div>
    <div class="col-12">
      <light-box
          :items="images"
          :index="index"
          :effect="'fade'"
          @close="index = null"
        />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import CoolLightBox from 'vue-cool-lightbox'
import useFbmState from '../useFbmStates'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import FbmItemWeight from './FbmItemWeight.vue'

export default {
  components: {
    'fbm-inventory-search': () => import('./FbmInventorySearch.vue'),
    'light-box': CoolLightBox,
    'fbm-item-weight': FbmItemWeight,
  },
  setup() {
    const { orderItems, currentOrder } = useFbmState()
    return {
      orderItems, currentOrder,
    }
  },
  data() {
    return {
      fbmInventorySearchCompId: '',
      images: [],
      index: null,
      searchData: {
        storeId: null,
        fbmOrderItemId: null,
        orderSku: null,
        orderId: null,
        isAdditionalItem: false,
        orderQuantity: 0,
      },
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  methods: {
    openSearchInventory(item) {
      this.searchData.storeId = this.currentOrder.storeId
      this.searchData.orderSku = item.orderSku
      this.searchData.fbmOrderItemId = item.id
      this.searchData.productName = item.productName
      // this.searchData.inventoryItemId = item.inventoryItemId
      this.searchData.shippedQuantityInt = item.shippedQuantityInt
      this.searchData.requiredReserveCount = item.requiredReserveCount
      this.searchData.smallImage = item.smallImage
      this.searchData.shippedQuantity = item.shippedQuantity
      this.searchData.orderQuantity = item.orderQuantity
      this.searchData.orderId = this.currentOrder.id
      this.searchData.isAdditionalItem = false
      this.fbmInventorySearchCompId = uuidv4()
    },
    enlargeImage(imageUrl) {
      if (imageUrl === '' || imageUrl === null || imageUrl === undefined) return
      this.images.length = 0
      this.index = 0
      this.images.push(imageUrl)
    },
    emptyItemWeight(weight) {
      return weight === 0 || weight === '' || weight === undefined || weight === null
    },
  },

}
</script>

<style lang="scss" scoped>
.custom-border {
  border-bottom: 0.5px solid rgb(68, 77, 85);
  margin-bottom: 6px;
  padding-bottom: 3px;
}
.custom-border:last-child {
  border-bottom: none;
}
</style>
